import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Terms from "../containers/Terms"

const TermsPage = () => {
  return (
    <Layout style={{ zIndex: 1 }}>
      <Seo title="Terms of Use" />
      <Terms />
    </Layout>
  )
}

export default TermsPage
